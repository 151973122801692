const source = 'personal-loan-widget'

/**
 * deprecated
 */
export function emitWidgetHeight() {
  if (typeof window === 'object') {
    window.parent.postMessage(
      {
        name: 'WIDGET_RESIZED',
        data: {
          source,
          scrollHeight: document.body.scrollHeight
        }
      },
      '*'
    )
  }
}

export function emitWidgetUpdatedEvent(data: { totalOffers: number }) {
  if (typeof window === 'object') {
    window.parent.postMessage(
      {
        name: 'WIDGET_UPDATED',
        data: {
          source,
          scrollHeight: document.body.scrollHeight,
          ...data
        }
      },
      '*'
    )
  }
}

// let the parent window show a modal
export function emitModalContent(content: string) {
  if (typeof window === 'object') {
    window.parent.postMessage(
      {
        name: 'SHOW_MODAL',
        data: {
          source,
          content
        }
      },
      '*'
    )
  }
}
