interface ErrorPayloadModel {
  message: string
  status?: string | number
  errors?: string[]
  stack?: string
  context?: unknown
}
/**
 * Class representing an Toolbox error.
 */
export default class ToolboxError extends Error {
  public status
  public errors
  public context

  constructor(payload: ErrorPayloadModel) {
    super(payload.message)
    // Name is required by the error class
    this.name = this.constructor.name
    this.stack = payload.stack || new Error().stack
    this.status = payload.status
    this.errors = payload.errors
    this.message = payload.message
    this.context = payload.context
  }
}
