import { FC, HTMLAttributes, useEffect, useState } from 'react'
import Icon from './Icon'
import Flag from './Flag'
import { Modal } from './Modal'
import { Rate } from '@libs/types/helm'
import CTA from './Cta'
import { useInView } from 'react-intersection-observer'
import { productViewed } from '../utils/tracking'
import { emitModalContent } from '../utils/message'

interface PersonalLoanTableRowProps
  extends HTMLAttributes<HTMLTableRowElement> {
  offer: Rate
  postion: number
}

const PersonalLoanTableRow: FC<PersonalLoanTableRowProps> = ({
  offer,
  postion,
  ...rest
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { ref, inView } = useInView({
    threshold: 0.5
  })

  const modalHtml = offer.termsAndConditions || ''

  const showModal = () => {
    if (typeof window === 'object') {
      // is the widget embedded in an iframe?
      if (window.self !== window.top) {
        // send message to parent window
        emitModalContent(modalHtml)
      } else {
        setIsModalOpen(true)
      }
    }
  }

  useEffect(() => {
    if (!inView) return
    productViewed(offer, postion)
  }, [inView, offer, postion])

  return (
    <tr
      className="display-grid grid-cols-3 sm:grid-cols-5 pos-relative rounded mb-4 sm:mb-0 bg-white box-shadow-2 sm:box-shadow-none"
      style={{ overflow: 'hidden' }}
      ref={ref}
      {...rest}
    >
      <td className="col-span-3 sm:col-span-1 px-0 sm:px-4 pt-8 mx-4 sm:mx-0 display-flex justify-content-space-between align-items-flex-end sm:align-items-flex-start">
        <Flag className="bg-blue-mid" left="0">
          PERSONAL LOAN
        </Flag>
        <div className="my-2">
          <img
            className="LenderLogo-image"
            src={
              offer.lender?.logo +
              '?optimize=medium&width=160&format=pjpg&auto=webp'
            }
            alt={`${offer.lender?.name || 'Lender'} logo`}
            loading="lazy"
            style={{ width: '100%', maxWidth: '160px', maxHeight: '64px' }}
          />
        </div>
      </td>
      <td className="col-span-1 sm:pt-8 sm:px-4 text-left border-0 sm:border-b">
        <div className="sm:display-none text-slate type-body-three mb-2">
          APR from
        </div>
        <div className="Numeral my-1">
          {offer.apr?.fixed?.min || '--'}
          <span className="Numeral-accent">%</span>
        </div>
        <div className="type-body-three sm:type-body-two">
          {(offer.apr?.subtext || '').replace(/autopay/gi, 'AutoPay')}
        </div>
      </td>
      <td className="col-span-1 sm:pt-8 sm:px-4 text-left border-0 sm:border-b">
        <div className="sm:display-none text-slate type-body-three mb-2">
          Term
        </div>
        <div className="Numeral my-1">
          {offer.term?.min && offer.term?.max
            ? `${offer.term?.min / 12}* - ${offer.term?.max / 12}*`
            : '--'}
        </div>
      </td>
      <td className="col-span-1 sm:pt-8 sm:px-4 text-left border-0 sm:border-b">
        <div className="sm:display-none text-slate type-body-three mb-2">
          Loan amount
        </div>
        <div className="Numeral my-1">
          <span className="Numeral-accent">$</span>
          {offer.amount?.min ? Math.ceil(offer.amount?.min / 1000) : '--'}
          k-<span className="Numeral-accent">$</span>
          {offer.amount?.max ? Math.floor(offer.amount?.max / 1000) : '--'}k
        </div>
      </td>
      <td className="col-span-3 pt-0 sm:pt-8 sm:col-span-1 sm:px-4 text-center border-0 sm:border-b">
        <div>
          <CTA offer={offer} position={postion}>
            Next
            <Icon
              variant="arrow"
              className="Icon--xs text-white ml-2"
              style={{ verticalAlign: 'text-top' }}
            />
          </CTA>
        </div>
        <div>
          <button
            className="Button Button--flat font-thin font-circular-book p-0 mt-4 Button--extraSmall"
            onClick={showModal}
          >
            Disclosures
          </button>
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <div
              className="text-left"
              dangerouslySetInnerHTML={{
                __html: modalHtml
              }}
            ></div>
          </Modal>
        </div>
      </td>
    </tr>
  )
}

export default PersonalLoanTableRow
