import { FC, ReactNode } from 'react'

interface ModalProps {
  header?: string
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}

export const Modal: FC<ModalProps> = ({
  header,
  isOpen,
  onClose,
  children
}) => {
  return (
    <div
      className={`Modal ${isOpen ? 'is-open' : 'is-closed'}`}
      data-modal
      role="dialog"
      aria-hidden={isOpen ? 'false' : 'true'}
    >
      <div className="Modal-overlay" onClick={onClose}></div>
      <div className="Modal-container" tabIndex={-1} role="document">
        <div
          className="w-full bg-white rounded-lg depth-level-four"
          style={{ maxWidth: '800px', overflow: 'auto' }}
        >
          {header && (
            <h3 className="type-heading-four p-8 mb-6 box-shadow-1">
              {header}
            </h3>
          )}
          <div className="type-body-one text-slate mb-0 p-8">{children}</div>
        </div>

        <button
          className="Modal-close"
          type="button"
          aria-label="Close modal"
          onClick={onClose}
        ></button>
      </div>
    </div>
  )
}
