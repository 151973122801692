import http from './http'
import { Rate, RateResponseDTO } from '../types/helm'

export type HelmRateDimensions = {
  loanAmount: number
  loanPurpose: string
  offerSource: 'generic' | 'bucketed'
  vertical: 'auto' | 'business' | 'personal'
  creditRating: number
  zipcode: string
}
/**
 * Full functionality of Helm API
 */
export const getOffers = async (
  dimensions: HelmRateDimensions
): Promise<Rate[]> => {
  const { data } = await http.get<RateResponseDTO>(
    `${process.env.NEXT_PUBLIC_HELM_API_URL}/rates`,
    {
      headers: {
        'x-source-key': process.env.NEXT_PUBLIC_HELM_SOURCE_KEY
      },
      params: {
        loan_amount: dimensions.loanAmount,
        loan_purpose: dimensions.loanPurpose,
        offer_source: dimensions.offerSource,
        vertical: dimensions.vertical,
        credit_rating: dimensions.creditRating,
        zipcode: dimensions.zipcode
      }
    }
  )

  return data.data
}

/**
 * Personal Loan API, simplified
 */
export const getPersonalLoanOffers = (
  dimensions: Pick<
    HelmRateDimensions,
    'creditRating' | 'loanAmount' | 'zipcode'
  >
): Promise<Rate[]> => {
  return getOffers({
    ...dimensions,
    loanPurpose: 'best',
    offerSource: 'generic',
    vertical: 'personal'
  })
}
