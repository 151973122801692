import * as tagular from '@libs/services/tagular'
import { Rate } from '@libs/types/helm'

function offerToProduct(offer: Rate, position: number) {
  return {
    brand: offer.lender?.name || '',
    category: 'personal loan',
    location: 'GRID',
    position,
    productId: String(offer.id || ''),
    variant: String(offer.apr?.fixed?.min || 0)
  }
}

export function productListViewed(offers: Rate[]) {
  tagular.productListViewed({
    listId: getContext('listId') || '',
    category: 'personal loan',
    product: offers.map((offer, index) => offerToProduct(offer, index + 1)),
    field: getContext('field') || [],
    paidListings: offers.length,
    editorialListings: 0,
    listName: 'all products'
  })
}

export function productClicked(offer: Rate, position: number) {
  tagular.productClicked({
    listId: getContext('listId') || '',
    correlationId: '',
    product: offerToProduct(offer, position)
  })
}

export function productViewed(offer: Rate, position: number) {
  tagular.productViewed({
    listId: getContext('listId') || '',
    product: offerToProduct(offer, position)
  })
}

/**
 * Since this is a widget, we need to get the context from outside of the widget
 */
function getContext(attr: string): any {
  // from global object
  if (
    typeof globalThis.rateTableContext === 'object' &&
    attr in globalThis.rateTableContext
  ) {
    return globalThis.rateTableContext[
      attr as keyof typeof globalThis.rateTableContext
    ]
  }

  // from url
  if (typeof window === 'object') {
    const url = new URL(window.location.href)
    const value = url.searchParams.get(attr)

    if (value !== null) {
      // try to decode and see if it's a valid JSON
      try {
        return JSON.parse(decodeURIComponent(value))
      } catch (_) {
        return value
      }
    }
  }

  return undefined
}
