// 🚨 this component was copied from mortgage rate table

import React, { FC, useState, useRef, ReactNode } from 'react'

interface Props {
  buttonText: JSX.Element | string
  className: string
  underline: boolean
  arrowPosition?: string
  positionBelow?: string
  style?: object
  dataTest?: string
  fullTooltipClass?: string
  icon?: string
  iconAltText?: string
  responsivePosition?: boolean
  children: ReactNode
}

const Tooltip: FC<Props> = ({
  buttonText,
  className,
  underline,
  children,
  arrowPosition = 'anchorLeft',
  positionBelow,
  style,
  dataTest,
  fullTooltipClass,
  icon,
  iconAltText,
  responsivePosition
}) => {
  let timeout: ReturnType<typeof setTimeout>
  const [isShown, setIsShown] = useState(false)
  const [tooltipPosition, setTooltipPosition] = useState(positionBelow)
  const buttonEl = useRef(null)

  const showTip = () => {
    timeout = setTimeout(() => {
      // adjust tooltip position automatically
      if (responsivePosition && buttonEl.current) {
        setTooltipPosition(
          (buttonEl.current as HTMLElement).getBoundingClientRect().top > 150
            ? 'Tooltip-content--aboveTrigger'
            : 'Tooltip-content--belowTrigger'
        )
      }
      setIsShown(true)
    }, 400)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setIsShown(false)
  }

  return (
    <div
      className={`Tooltip ${fullTooltipClass}`}
      data-tooltip="container"
      data-tooltip-type="clickable"
      data-testid={`${dataTest}`}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      <button
        className={`Tooltip-trigger ${
          underline ? 'Tooltip-trigger--underline' : ''
        } bg-transparent ${className}`}
        type="button"
        data-tooltip="trigger"
        aria-controls="tooltip-two"
        aria-expanded="false"
        style={style}
        ref={buttonEl}
      >
        {icon && <img src={icon} alt={iconAltText} className="mr-2" />}
        {buttonText}
      </button>
      {isShown && (
        <div
          className={`Tooltip-content Tooltip-content--${arrowPosition} z-layer-five ${tooltipPosition} display-none sm:display-block`}
          id="tooltip-two"
          role="tooltip"
          data-tooltip="content"
          aria-hidden="true"
        >
          <div className="Tooltip-body">
            <div className="Tooltip-arrow"></div>
            {children}
          </div>
        </div>
      )}
    </div>
  )
}

export default Tooltip
