import { FC, HTMLAttributes, memo } from 'react'

// use https://jakearchibald.github.io/svgomg/ to optimize SVGs
const variants = {
  'caret-down': (
    <svg
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
    >
      <path
        d="M14 6.9L12.5 5.5L8 9.7L3.5 5.5L2 6.9L8 12.5L14 6.9Z"
        fill="currentColor"
        className="icon-base"
      ></path>
      <title>Caret Down</title>
    </svg>
  ),
  'caret-up': (
    <svg
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
    >
      <path
        d="M2.02441 10.5992L3.49975 12.0251L8.07237 7.90431L12.4984 12.1822L14.0226 10.8086L8.12123 5.10474L2.02441 10.5992Z"
        fill="currentColor"
        className="icon-base"
      ></path>
      <title>Caret Up</title>
    </svg>
  ),
  info: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Information icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM7 5.0122C7 4.45122 7.45122 4 8 4C8.56098 4 9 4.45122 9 5.0122C9 5.54878 8.56098 6 8 6C7.45122 6 7 5.54878 7 5.0122ZM7 12V7H9V12H7Z"
        fill="#0157FF"
      />
    </svg>
  ),
  arrow: (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
    >
      <path
        d="M20 12L13.8863 18L12.3579 16.5L15.7204 13.2H3V10.8H15.7204L12.3579 7.5L13.8863 6L20 12Z"
        className="Icon-glyph"
        fill="currentColor"
      ></path>
      <title>Arrow Right</title>
    </svg>
  )
}

interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  variant: keyof typeof variants
}

const Icon: FC<IconProps> = ({ className = '', variant, ...rest }) => {
  return (
    <span className={`Icon ${className}`} {...rest}>
      {variants[variant]}
    </span>
  )
}

export default memo(Icon)
