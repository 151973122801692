interface Product {
  productId: string
  category: string
  brand: string
  location: string
  position: number
  variant?: string
  name?: string
}

export interface ProductListViewedPayload {
  listId: string
  category: string
  product: Product[]
  field: {
    fieldName: string
    fieldValue: string
  }[]
  paidListings: number
  editorialListings: number
  listName: string
}

export interface ProductClickedPayload {
  listId: string
  correlationId: string
  product: Product
}

export interface ProductViewedPayload {
  listId: string
  product: Product
}

/**
 * make the actual call to tagular
 */
function fireBeam(eventName: string, eventType: string, eventPayload: any) {
  const payload = {
    '@type': eventType,
    ...eventPayload
  }

  const tagular = (globalThis as any).tagular

  if (typeof tagular === 'function') {
    tagular('beam', eventName, payload)
  }

  if (process.env.NODE_ENV === 'development') {
    console.log('Event Fired:', eventName, payload)
  }
}

export function productListViewed(payload: ProductListViewedPayload) {
  fireBeam(
    'ProductListViewed',
    'redventures.ecommerce.v1.ProductListViewed',
    payload
  )
}

export function productClicked(payload: ProductClickedPayload) {
  fireBeam('ProductClicked', 'redventures.ecommerce.v1.ProductClicked', payload)
}

export function productViewed(payload: ProductViewedPayload) {
  fireBeam('ProductViewed', 'redventures.ecommerce.v1.ProductViewed', payload)
}
