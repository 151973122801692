import { FC, HTMLAttributes } from 'react'
import TableHeadBtn, { TableHeadBtnProps } from './TableHeadBtn'

interface TableHeadProps extends HTMLAttributes<HTMLTableRowElement> {
  columns: Array<TableHeadBtnProps & { className?: string }>
}

const TableHead: FC<TableHeadProps> = ({ columns, ...rest }) => {
  return (
    <thead>
      <tr {...rest}>
        {columns.map(({ name, tooltip, className, orderBy, onSort }) => (
          <th key={name} className={className}>
            <TableHeadBtn
              name={name}
              tooltip={tooltip}
              orderBy={orderBy}
              onSort={onSort}
            />
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHead
