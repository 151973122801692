import { FC, ReactNode, HTMLAttributes } from 'react'

interface TableProps extends HTMLAttributes<HTMLTableElement> {
  head: ReactNode
  children: ReactNode
}

const Table: FC<TableProps> = ({ head, children, ...rest }) => {
  return (
    <table {...rest}>
      {head}
      <tbody>{children}</tbody>
    </table>
  )
}

export default Table
