import { FC, HTMLAttributes, useState } from 'react'
import Table from './table/Table'
import TableHead from './table/TableHead'
import PersonalLoanTableRow from './PersonalLoanTableRow'
import { Rate } from '@libs/types/helm'
import { OnSortFunc, OrderBy } from './table/TableHeadBtn'
import { sortPersonalLoanOffers } from '../utils/sorting'
import Icon from './Icon'
import { useEffect } from 'react'
import { productListViewed } from '../utils/tracking'
import { emitWidgetUpdatedEvent } from '../utils/message'

interface PersonalLoanTableProps extends HTMLAttributes<HTMLTableElement> {
  offers: Rate[]
}

const OFFERS_PER_PAGE = 3

const PersonalLoanTable: FC<PersonalLoanTableProps> = ({ offers, ...rest }) => {
  // `allOffers` will be used for sorting, while `offers` remains unchanged
  const [allOffers, setAllOffers] = useState<Rate[]>(offers)

  const [sortBy, setSortBy] = useState<string | null>(null)
  const [orderBy, setOrderBy] = useState<OrderBy>(null)

  const [page, setPage] = useState(1)

  const slicedOffers = allOffers.slice(0, page * OFFERS_PER_PAGE)

  useEffect(() => {
    setAllOffers(offers)
    setPage(1)
    setSortBy(null)
    setOrderBy(null)
  }, [offers])

  useEffect(() => {
    if (slicedOffers.length) {
      productListViewed(slicedOffers)
    }
    window.setTimeout(
      () => emitWidgetUpdatedEvent({ totalOffers: allOffers.length }),
      100
    )
  }, [slicedOffers])

  const onSort: OnSortFunc = (col, order) => {
    setSortBy(col)
    setOrderBy(order)
    setAllOffers(sortPersonalLoanOffers(allOffers, col, order))
  }

  const PersonalLoanTableHead = (
    <TableHead
      className="display-none sm:display-grid grid-cols-5"
      columns={[
        {
          name: 'Lender Partner'
        },
        {
          name: 'APR from',
          tooltip: (
            <div className="type-body-three">
              The annual percentage (APR) includes your interest rate, plus any
              loan fees. It reflects the total cost of borrowing.
            </div>
          ),
          className: 'text-left',
          onSort,
          orderBy: sortBy === 'APR from' ? orderBy : undefined
        },
        {
          name: 'Term',
          className: 'text-left',
          onSort,
          orderBy: sortBy === 'Term' ? orderBy : undefined
        },
        {
          name: 'Loan amount',
          className: 'text-left',
          onSort,
          orderBy: sortBy === 'Loan amount' ? orderBy : undefined
        },
        {
          name: 'As of ' + new Date().toLocaleDateString()
        }
      ]}
    />
  )

  return (
    <>
      <Table
        head={PersonalLoanTableHead}
        className={`Table Table--borderedRows Table--spacingMd`}
        {...rest}
      >
        {slicedOffers.map((offer, index) => (
          <PersonalLoanTableRow
            key={offer.id}
            offer={offer}
            postion={index + 1}
          />
        ))}
      </Table>

      {slicedOffers.length < allOffers.length && (
        <div className="text-center">
          <button
            type="button"
            className="Button Button--flat"
            onClick={() => setPage(page + 1)}
          >
            Show more{' '}
            <Icon
              className="Icon--xs"
              variant="caret-down"
              style={{ verticalAlign: 'top' }}
            />
          </button>
        </div>
      )}
    </>
  )
}

export default PersonalLoanTable
