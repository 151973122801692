import { FC, ReactNode } from 'react'
import Icon from '../Icon'
import Tooltip from '../Tooltip'

export type OrderBy = 'ASC' | 'DESC' | null
export type OnSortFunc = (col: string, order: OrderBy) => void
export interface TableHeadBtnProps {
  name: string
  tooltip?: string | ReactNode
  orderBy?: OrderBy
  onSort?: OnSortFunc
}

const TableHeadBtn: FC<TableHeadBtnProps> = ({
  name,
  tooltip,
  orderBy,
  onSort
}) => {
  // click <th> to sort
  const onClick = () => {
    if (onSort) {
      const newOrderByValue = orderBy === 'ASC' ? 'DESC' : 'ASC'
      onSort(name, newOrderByValue)
    }
  }

  const iconStyle = { width: 12, height: 12, lineHeight: 0, margin: '-1px 0' }

  return (
    <div
      className="display-inline-flex align-items-center h-full"
      onClick={onClick}
      style={{ cursor: onSort ? 'pointer' : 'default' }}
    >
      <div className="type-body-two text-slate">{name}</div>

      {tooltip && (
        <Tooltip
          buttonText={
            <Icon
              variant="info"
              className="Icon--xs ml-1"
              style={{ marginTop: '-2px' }}
            />
          }
          className={'leading-none'}
          underline={false}
        >
          {tooltip}
        </Tooltip>
      )}

      {onSort && (
        <button
          type="button"
          className="Button Button--bare display-flex flex-direction-column ml-1"
        >
          <Icon
            variant="caret-up"
            className={`Icon--xs ${
              orderBy === 'ASC' ? 'text-blue' : 'text-slate'
            }`}
            style={iconStyle}
          />
          <Icon
            variant="caret-down"
            className={`Icon--xs ${
              orderBy === 'DESC' ? 'text-blue' : 'text-slate'
            }`}
            style={iconStyle}
          />
          <span className="visually-hidden">Sorting arrows</span>
        </button>
      )}
    </div>
  )
}

export default TableHeadBtn
