import { FC, MouseEventHandler, ReactNode } from 'react'
import { v4 } from 'uuid'
import { Rate } from '@libs/types/helm'
import { productClicked } from '../utils/tracking'

interface CTAProps {
  offer: Rate
  position: number
  children: ReactNode
}

const assignClickId = (url: string) => {
  url = url.replace('[CLICK_ID]', v4())
  return url
}

/* from PM:
  Allie Jaccard
  Can you override the URL being returned from the endpoint? For example, MyFi override the URL returned and just always sends users to:
  https://www.bankrate.com/loans/personal-loans/prequalification/?cid=[CLICK_ID]
  So for Lightstream & Citi can you override the URL and send these two lenders to the PL Prequal flow?
*/
const mutatedDestUrl = (offer: Rate) => {
  if (
    offer.lender?.name?.startsWith('Citi') ||
    offer.lender?.name?.startsWith('LightStream')
  ) {
    return 'https://www.bankrate.com/loans/personal-loans/prequalification/?cid=[CLICK_ID]'
  }

  return offer.destination?.trackingUrl || ''
}

const CTA: FC<CTAProps> = ({ offer, position, children }) => {
  const origUrl = mutatedDestUrl(offer)
  const url = assignClickId(origUrl)

  const onClick: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    // change click id per click
    window.open(assignClickId(origUrl), '_blank')
    productClicked(offer, position)
  }

  return (
    <a
      target="_blank"
      rel="noopener noreferrer nofollow"
      href={url}
      className="Button Button--primary w-full m-0 center-content text-lg md:text-base leading-relaxed"
      onClick={onClick}
    >
      {children}
    </a>
  )
}

export default CTA
