import type { NextPage, GetServerSideProps } from 'next'
import { useState, useEffect } from 'react'
import PersonalLoanTable from '../components/PersonalLoanTable'
import { Rate } from '@libs/types/helm'
import { getPersonalLoanOffers } from '@libs/services/helm'
import Head from 'next/head'
import featuresContext from '../context/features'

interface IndexPageProps {
  ssr?: boolean
  ssrOffers?: Rate[]
  error?: string
  features?: Record<string, unknown>
}

const Index: NextPage<IndexPageProps> = ({
  ssr = false,
  ssrOffers = [],
  error,
  features = {}
}) => {
  const [offers, setOffers] = useState<Rate[]>(ssrOffers)

  // Once users update dimensions, we need to fetch new offers
  // Example:
  //
  // window.postMessage({
  //   name: 'RATE_TABLE_DIMENSIONS_UPDATED',
  //   data: { loanAmount:100000, zipCode:33301 }
  // })
  //
  useEffect(() => {
    const fetchOffers = async (context: Record<string, unknown>) => {
      try {
        const { loanAmount, zipCode, creditScore = 780 } = context
        const freshOffers = await getPersonalLoanOffers({
          creditRating: Number(creditScore),
          loanAmount: Number(loanAmount),
          zipcode: String(zipCode)
        })
        setOffers(freshOffers)
      } catch (error) {
        // todo
        console.log('Failed to fetch personal loan offers', error)
      }
    }

    // if ssr is disabled, we need to fetch offers on client side
    if (!ssr) {
      const context = globalThis.rateTableContext
      if (context) fetchOffers(context)
    }

    const msgListener: (e: MessageEvent) => void = async ({ data = {} }) => {
      switch (data.name) {
        case 'RATE_TABLE_DIMENSIONS_UPDATED': {
          await fetchOffers(data.data)
          break
        }
      }
    }

    window.addEventListener('message', msgListener)

    return () => window.removeEventListener('message', msgListener)
  }, [ssr])

  if (error) {
    return (
      <p className="mb-4 text-slate">Personal loan offer widget: {error}</p>
    )
  }

  return (
    <featuresContext.Provider value={features}>
      <Head>
        <title>Personal loan offer widget</title>
      </Head>
      <main className={`${offers.length ? '' : 'display-none'}`}>
        <div className="px-4 sm:px-0">
          <h4 className="mt-4 mb-2">Compare similar offers</h4>
          <p className="mb-4 text-slate">
            Personal loans serve as another option for borrowers, although home
            equity products typically offer lower rates by using your home as
            collateral.
          </p>
        </div>
        <PersonalLoanTable offers={offers} />
      </main>
    </featuresContext.Provider>
  )
}

export default Index

export const getServerSideProps: GetServerSideProps<
  IndexPageProps
> = async context => {
  const props: IndexPageProps = {
    ssr: Boolean(context.query.ssr),
    ssrOffers: [],
    features: {}
  }

  if (props.ssr) {
    const { loan_amount, zipcode, credit_rating = 780 } = context.query

    if (loan_amount && zipcode) {
      try {
        props.ssrOffers = await getPersonalLoanOffers({
          creditRating: Number(credit_rating),
          loanAmount: Number(loan_amount),
          zipcode: String(zipcode)
        })
      } catch (error) {
        // todo
        console.log(error)
        props.error = (error as Error).message
      }
    } else {
      props.error = 'Missing required query params(zipcode, loan_amount)'
    }
  }

  return { props }
}
