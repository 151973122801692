import pick from 'lodash/pick'
import axiosRetry from 'axios-retry'
import axios, { AxiosError } from 'axios'
import ToolboxError from '../utils/error'

/** Creates a custom instance */
const http = axios.create({
  timeout: 60000,
  headers: {
    'User-Agent': `axios/toolbox:${process.env.APP_NAME}:${process.env.APP_ENV}`
  }
})

/** Will retry requests 3 times before rejecting promises */
axiosRetry(http, {
  retries: 3,
  retryDelay: retryCount => retryCount * 1000
})

/** Normalize errors before rejecting */
http.interceptors.response.use(
  res => res,
  (error: AxiosError) => {
    return Promise.reject(
      new ToolboxError({
        status: error?.response?.status,
        stack: error.stack,
        context: {
          config: JSON.stringify(
            pick(error.config, 'headers', 'url', 'method', 'data')
          ),
          response: JSON.stringify(
            pick(error?.response, 'headers', 'data', 'status', 'statusText')
          )
        },
        message: error.message
      })
    )
  }
)

/** Export modified client */
export default http

/** Export everything else from axios to simplify imports */
export * from 'axios'
