import { FC, HTMLAttributes, memo } from 'react'

interface FlagPros extends HTMLAttributes<HTMLSpanElement> {
  top?: string
  left?: string
  right?: string
}

const Flag: FC<FlagPros> = ({
  top = 0,
  left,
  right,
  className = '',
  children,
  ...rest
}) => {
  return (
    <span
      className={`display-inline-block pos-absolute type-heading-five text-white py-3 px-4 ${className}`}
      style={{ top, left, right }}
      {...rest}
    >
      {children}
    </span>
  )
}

export default memo(Flag)
