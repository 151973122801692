/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Rate } from '@libs/types/helm'
import { OrderBy } from '../components/table/TableHeadBtn'
import partition from 'lodash/partition'

export function sortPersonalLoanOffers(
  offers: Rate[],
  sortBy: string,
  orderBy: OrderBy
): Rate[] {
  switch (sortBy) {
    case 'APR from': {
      const [sortableOffers, unsortableOffers] = partition(
        offers,
        o => o.apr?.fixed?.min
      )

      const sortedOffers = sortableOffers.sort((a, b) => {
        const bool = a.apr!.fixed!.min! > b.apr!.fixed!.min!
        return orderBy === 'ASC' ? (bool ? 1 : -1) : bool ? -1 : 1
      })

      return [...sortedOffers, ...unsortableOffers]
    }

    case 'Term': {
      if (orderBy === 'ASC') {
        const [sortableOffers, unsortableOffers] = partition(
          offers,
          o => o.term?.min
        )
        const sortedOffers = sortableOffers.sort((a, b) => {
          const bool = a.term!.min! > b.term!.min!
          return bool ? 1 : -1
        })

        return [...sortedOffers, ...unsortableOffers]
      } else {
        const [sortableOffers, unsortableOffers] = partition(
          offers,
          o => o.term?.max
        )
        const sortedOffers = sortableOffers.sort((a, b) => {
          const bool = a.term!.max! > b.term!.max!
          return bool ? -1 : 1
        })

        return [...sortedOffers, ...unsortableOffers]
      }
    }

    case 'Max loan amount': {
      const [sortableOffers, unsortableOffers] = partition(
        offers,
        o => o.amount?.max
      )

      const sortedOffers = sortableOffers.sort((a, b) => {
        const bool = a.amount!.max! > b.amount!.max!
        return orderBy === 'ASC' ? (bool ? 1 : -1) : bool ? -1 : 1
      })

      return [...sortedOffers, ...unsortableOffers]
    }

    case 'Loan amount': {
      const [sortableOffers, unsortableOffers] = partition(
        offers,
        o => o.amount?.min
      )

      const sortedOffers = sortableOffers.sort((a, b) => {
        const bool = a.amount!.min! > b.amount!.min!
        return orderBy === 'ASC' ? (bool ? 1 : -1) : bool ? -1 : 1
      })

      return [...sortedOffers, ...unsortableOffers]
    }
  }

  return [...offers]
}
